const sendEmail = async (
  email,
  last4,
  firstName = 'John',
  lastName = 'Doe',
  pwd,
  is_partial_refund = false
) => {
  let res = await fetch(
    'https://3730rgrgag.execute-api.us-east-1.amazonaws.com/default/apaceDemoEmail',
    {
      method: 'POST',
      body: JSON.stringify({
        email,
        firstName,
        lastName,
        last4,
        pwd,
        is_partial_refund,
      }),
    }
  );
  console.log('res', res);
};

export default sendEmail;
