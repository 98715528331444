import { useState } from 'react';
import { BrowserRouter as Routes, Route } from 'react-router-dom';
import CreateEmailPage from './components/CreateEmailPage';
// import MainRefundPage from './components/MainRefundPage';
// import CreateRefundPage from './components/CreateRefundPage';
import AppContext from './utils/appContext';
import 'antd/dist/antd.css';
import './App.css';
import LogRocket from 'logrocket';

function App() {
  const [last4, setLast4] = useState('');
  const context = { last4, setLast4, LogRocket };
  LogRocket.init('hrmozg/apace-demo');

  return (
    <AppContext.Provider value={context}>
      <Routes>
        <Route path="/" exact component={CreateEmailPage} />
        {/* <Route path="/get-refund" component={MainRefundPage} />
        <Route path="/create-refund" component={CreateRefundPage} /> */}
      </Routes>
      <div
        style={{
          bottom: 0,
          padding: 2,
          width: '100%',
          color: 'white',
          position: 'fixed',
          textAlign: 'center',
          backgroundColor: '#234D7D',
        }}
      >
        You are on the Apace DEMO version
      </div>
    </AppContext.Provider>
  );
}

export default App;
