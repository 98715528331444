import React, { useContext, useState } from 'react';
import { Button, Card, Divider, Input, notification, Switch } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import ApaceLogo from '../assets/svg/MainLogo.svg';
import sendEmail from '../utils/sendEmail';
import Lottie from 'react-lottie';
import locked_vault from '../assets/lottie/38409-vault.json';
import email_sending from '../assets/lottie/95247-email.json';
import appContext from '../utils/appContext';

export default function CreateEmailPage({ history }) {
  const [email, setEmail] = useState('');
  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [sending, setSending] = useState(false);
  const [last4, setLast4] = useState('');
  const [showError, setShowError] = useState('');
  const [is_partial_refund, setIs_partial_refund] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const { LogRocket } = useContext(appContext);

  let regex = new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,3}');
  let queires = new URLSearchParams(history.location.search);
  let pwd = queires.get('pwd');

  const Locked = () => (
    <div style={{ textAlign: 'center', padding: '50px 5px' }}>
      <img
        style={{ height: 80, top: 200, position: 'relative', margin: 'auto', zIndex: 10 }}
        src={ApaceLogo}
        alt="Apace logo"
      />
      <h1>Sorry, this page is locked</h1>
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: locked_vault,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMin slice',
          },
        }}
        height={400}
        width={300}
      />
      <p>Please contact support for your unique link</p>
    </div>
  );

  const EmailSent = () => (
    <div style={{ textAlign: 'center', padding: '50px 5px' }}>
      <img
        style={{
          height: 30,
          top: 357,
          left: -35,
          position: 'relative',
          margin: 'auto',
          zIndex: 10,
        }}
        src={ApaceLogo}
        alt="Apace logo"
      />
      <h1>Your instant refund email is on its way</h1>
      <Lottie
        options={{
          loop: false,
          autoplay: true,
          animationData: email_sending,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMin slice',
          },
        }}
        height={400}
        width={400}
      />
      <p>Check your primary and promotions inbox</p>
    </div>
  );

  const handleSendEmail = async () => {
    if (!email || !regex.test(email) || !last4) {
      setShowError(true);
      return notification.error({
        message: 'Oops!',
        description: 'The email and last 4 of card fields are required',
      });
    }

    setSending(true);
    LogRocket.identify(email, { firstName, lastName });
    await sendEmail(email, last4, firstName, lastName, pwd, is_partial_refund);
    notification.success({
      message: 'Success!',
      description: `The email was sent successful to ${email}`,
    });
    setEmail('');
    setLastName('');
    setFirstName('');
    setLast4('');
    setSending(false);
    setShowError(false);
    setIs_partial_refund(false);
    setEmailSent(true);
  };

  const Label = ({ children, required }) => (
    <p style={{ marginBottom: 2, color: 'grey', textAlign: 'left' }}>
      {children} {required ? <span style={{ color: 'red' }}>*</span> : null}
    </p>
  );

  return !pwd || pwd.length < 27 ? (
    <Locked />
  ) : (
    <div style={{ textAlign: 'center', maxWidth: 500, margin: 'auto' }}>
      <div>
        <img style={{ height: 100 }} src={ApaceLogo} alt="Apace logo" />
      </div>
      {emailSent ? (
        <EmailSent />
      ) : (
        <Card title="Consumer user experience DEMO" bordered>
          <Card
            style={{ backgroundColor: '#f3f3f3', fontSize: 12, textAlign: 'left' }}
            bodyStyle={{ padding: 10 }}
          >
            You'll enter the recipients email (required) and name (optinal) and we will send a demo
            email to the email you entered
            <br />
            <strong>Remember: this is just a demo</strong>
          </Card>
          <Divider />
          <Label required>Email</Label>
          <Input
            required
            size="large"
            value={email}
            placeholder="Recipients email"
            onChange={({ target }) => setEmail(target.value)}
            style={{ borderColor: showError && (!email || !regex.test(email)) ? 'red' : null }}
          />
          <div style={{ display: 'flex', marginTop: 10, gap: 40 }}>
            <div>
              <Label>First name</Label>
              <Input
                required
                size="large"
                value={firstName}
                placeholder="First name"
                style={{ width: '100%' }}
                onChange={({ target }) => setFirstName(target.value)}
              />
            </div>
            <div>
              <Label>Last name</Label>
              <Input
                required
                size="large"
                value={lastName}
                placeholder="Last name"
                style={{ width: '100%' }}
                onChange={({ target }) => setLastName(target.value)}
              />
            </div>
          </div>
          <div
            style={{
              fontSize: 12,
              marginTop: 40,
              borderRadius: 5,
              textAlign: 'left',
              padding: '10px 15px',
              background: '#F6F8F9',
              border: 'solid #234D7D 1px',
            }}
          >
            <p>
              On our demo you'll be getting <strong>$1</strong> into your bank account instantly and
              you'll be required to enter the last 4 digits of the card you you'll use for this demo
            </p>
            <div>
              <p style={{ marginBottom: 4, fontWeight: 600 }}>Last 4 digits</p>
              <Input
                value={last4}
                placeholder="* * * *"
                style={{
                  width: '60%',
                  borderColor: showError && last4.length !== 4 ? 'red' : null,
                }}
                onChange={({ target }) => setLast4(target.value)}
              />
              <p
                style={{
                  marginTop: 4,
                  marginBottom: 0,
                  fontSize: 10,
                  lineHeight: 1.2,
                  width: '60%',
                }}
              >
                That's the last 4 digits of the card you'll be using for the demo to get your $1
              </p>
            </div>
          </div>
          <div style={{ textAlign: 'left' }}>
            <p style={{ marginBottom: 0, marginTop: 20 }}>Partial refund</p>
            <Switch
              checked={is_partial_refund}
              onChange={() => setIs_partial_refund(!is_partial_refund)}
            />
            <p
              style={{
                marginTop: 4,
                marginBottom: 0,
                fontSize: 10,
                lineHeight: 1.2,
              }}
            >
              You'll be able to see how a partial refund looks like
            </p>
          </div>

          <Button
            size="large"
            shape="round"
            loading={sending}
            disabled={sending}
            onClick={handleSendEmail}
            style={{
              width: '100%',
              height: 47,
              fontWeight: 600,
              marginTop: 40,
              marginBottom: 25,
              backgroundColor: '#234D7D',
              color: 'white',
              borderColor: 'none',
            }}
          >
            SEND REFUND DEMO <MailOutlined />
          </Button>
        </Card>
      )}
    </div>
  );
}
